:root {
  --p1x: 50;
  --p1xNew: 255;
  --p1y: 50;
  --p1yNew: 55;
  --cp1x: 100;
  --cp1y: 50;
  --cp2x: 194;
  --cp2y: 209;
  --p2x: 250;
  --p2y: 50;
  --div-offset: -12px;
  --duration: 2000ms;
  --flowImageWidth: 100%;
  --flowImageHeight: 900px;

  --backgroundColor: #292625;
  --buttonHighlightColor: #6FB5E9;
  --buttonTextColor: #bff4ff;
  --buttonColor: #3D8FD7;
  --buttonOldColor: #0096c7;

  --backgroundColor: #101729;
  --buttonColor: #49A4E3;
  --buttonTextColor: white;
}


body {
  background-color: var(--backgroundColor);
}


ul {
  list-style-type: none;
  padding-left: 0;
  font-family: 'Heebo', sans-serif;
  text-align: left;
  padding: 0 0 0 5px;
}


.abel {
  font-family: 'Abel', sans-serif;
  font-size: 20px;
}


.heebo {
  font-family: 'Heebo', sans-serif;
}


.kalnia {
  font-family: 'Kalnia', serif;
}


.darker {
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: 200;
}


.contentTitle {
  font-size: 3rem;
  color: #2E3F6D;
}


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}


.App-header {
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}


.App-link {
  color: #61dafb;
}


.flowStep {
  border-radius: 20px;
  border: 1px solid #666666;
  font-size: 13px;
  padding: 10px;
  position: relative;
}

.flowStep:hover {
  background-color: #bee9f5;
}


.flowDiagram {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
  width: 500px
}


.flowImages{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #CCCCCC;
  padding: 5px;
  width: var(--flowImageWidth);
  height: var(--flowImageHeight);
  overflow: auto;
  z-index: 99;
}


/** 
 * --------------------------------------------------------------------------

   TOP MENU STYLES

 * --------------------------------------------------------------------------
 * the outside containing div that controls where the buttons are
 * with respect to the page size
 */
.menuOutsideDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow:row wrap;
  width: 90%;
  justify-content: center;
  align-items: flex-start;
  vertical-align: top;
  padding: 10px 0 10px 0;
  border: 1px solid red;
}

/** 
 * provides an enclosed environment for the button so it can expand/contract
 * and not impact other buttons
 */
.menuContainerDiv {
  display: inline-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow:row wrap;
  justify-content: center;
  z-index: 999;
}

.menuDiv.hover {
  background-color: #a5cbff;
}

.menuSubDiv {
  display: none;
  justify-content: left;
  width: 99%;
  z-index: '100';
  font-size: 15px;

}

.menuHoverDescription {
  display: none;
  justify-content: center;
  font-size: 14px;
  width: 100%;
}

.menuContentExtra {
  align-items: center;
  color: white;
  width: 60%;
}






@media (min-width: 1001px) and (max-width: 5000px) {
  .title {
      font-family: 'Heebo', sans-serif;
      font-size: 32px;
      color: #98A5B9;
      vertical-align: middle;
      margin: 0px;
      background-color: #001f49;
  }

  /** 
   * Div for the button itself
   */
  .menuDiv {
    color: var(--buttonTextColor);
    background-color: var(--buttonColor);
    padding: 5px 9px 3px 6px;
    position: relative;
    align-items: center;

    font-family: 'Heebo', sans-serif;
    font-size: 20px;
    font-weight: 600;

    justify-content: center;
    border-radius: 17px;
    width: 100px;
    margin: 20px;
  }
}

@media (min-width: 801px) and (max-width: 1000px) {
  .title {
      font-family: 'Heebo', sans-serif;
      font-size: 28px;
      color: #98A5B9;
      vertical-align: middle;
      margin: 0px;
      background-color: #001f49;
  }

  /** 
   * Div for the button itself
   */
   .menuDiv {
      color: var(--buttonTextColor);
      background-color: var(--buttonColor);
      padding: 5px 9px 3px 6px;
      position: relative;
      align-items: center;

      font-family: 'Heebo', sans-serif;
      font-size: 18px;
      font-weight: 600;

      justify-content: center;
      border-radius: 17px;
      width: 100px;
  }
}

@media (min-width: 701px) and (max-width: 800px) {
  .title {
      font-family: 'Heebo', sans-serif;
      font-size: 24px;
      color: #98A5B9;
      vertical-align: middle;
      margin: 0px;
      background-color: #001f49;
  }

    /** 
   * Div for the button itself
   */
   .menuDiv {
      color: var(--buttonTextColor);
      background-color: var(--buttonColor);
      padding: 5px 9px 3px 6px;
      position: relative;
      align-items: center;

      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 600;

      justify-content: center;
      border-radius: 17px;
      width: 80px;
  }
}

@media (min-width: 100px) and (max-width: 700px) {
  .title {
      font-family: 'Heebo', sans-serif;
      font-size: 20px;
      color: #98A5B9;
      vertical-align: middle;
      margin: 0px;
      background-color: #001f49;
  }

      /** 
   * Div for the button itself
   */
   .menuDiv {
      color: var(--buttonTextColor);
      background-color: var(--buttonColor);
      padding: 5px 4px 3px 4px;
      position: relative;
      align-items: center;

      font-family: 'Heebo', sans-serif;
      font-size: 14px;
      font-weight: 600;

      justify-content: center;
      border-radius: 17px;
      width: 70px;
  }
}








.menu01{
  display: inline-block;
}

.menu02Content {
  display: none;
}

.menu03Content {
  display: none;
}

.menu04Content {
  display: none;
}




.section01Content {
  border: 1px solid #2E3F6D;
  position: absolute;
  top: 500px;
  border-radius: 5px;
  padding: 1.5rem;
  color: #BED0F1;
  min-width: 300px; 
  max-width: 590px;
  display: inline-block;
}

.section02Content {
  border: 1px solid #2E3F6D;
  position: absolute;
  top: 500px;
  border-radius: 5px;
  padding: 1.5rem;
  color: #BED0F1;
  min-width: 300px; 
  max-width: 590px;
  display: inline-block;
}

.section03Content {
  border: 1px solid #2E3F6D;
  position: absolute;
  top: 500px;
  border-radius: 5px;
  padding: 1.5rem;
  color: #BED0F1;
  min-width: 300px; 
  max-width: 590px;
  display: none;
}

.section04Content {
  border: 1px solid #2E3F6D;
  position: absolute;
  top: 500px;
  border-radius: 5px;
  padding: 1.5rem;
  color: #BED0F1;
  min-width: 300px; 
  max-width: 590px;
  display: none;
}



/**
 * content container
 */
.contentDiv{
  border: 1px solid #2E3F6D;
  position: absolute;
  top: 200px;
  border-radius: 5px;
  padding: 1.5rem;
  color: #BED0F1;
  min-width: 300px; 
  max-width: 590px;
  display: inline-block;
}


.main {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: inline-block;
}

.experience {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: inline-block;
}

.skillset {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: inline-block;
}

.certifications {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: inline-block;
}

.cx {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: none;
}

.art {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: none;
}

.contact {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: none;
}

.uxAnalysis {
  text-align: left;
  border: 1px solid #2E3F6D;
  color: #BED0F1;
  padding: .5rem;
  margin: .5rem;
  border-radius: 5px;
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contactSocial {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100px; 
  left: 0px;
  z-index: 999;
  margin: 20px 0 20px 10px;
}

/* https://codepen.io/danwilson/pen/oBrOGW */

.p1-motion {
  transform: translate3d(var(--p1x), 300px, 0);

  transition: transform var(--duration) linear;
  will-change: transform;
}

@keyframes p1-moving {
  from {
    transform: translateX( var(--p1x) );
  }
  to {
    transform: translateX( var(--p1xNew) );
  }
}

/* ---------------------- */


